<template>
    <div>
        <page-header :title="`Chi tiết khảo sát`" :items="breadcrumbs" class="mb-3" />
        <btn-change-page />
        <div class="card mt-3">
            <div class="card-body">
                <div class="row mb-2">
                </div>
                <div v-show="!loading" class="table-responsive">
                    <table class="table table-hover table-centered table-nowrap">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">STT</th>
                                <th scope="col" class="style-sender">Người trả lời</th>
                                <th scope="col">Thời gian</th>
                                <th scope="col">Số điện thoại/ Email</th>
                                <th scope="col" class="text-center">
                                    Câu hỏi và trả lời
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(entry, index) in entries" :key="entry.id">
                                <td>
                                    {{ (pagination.page - 1)*10 + index + 1 }}
                                </td>
                                <td>
                                    {{ entry.userName }}
                                </td>
                                <td>{{ entry.thoiGian | formatDate }}</td>
                                <td>{{ entry.user }}</td>
                                <div v-for="(ques, index) in entry.listQA" :key="index">
                                    <td class="col" style="min-width: 200px;">Câu hỏi {{index+1}}: {{ ques.question }}</td>
                                    <td class="col" style="min-width: 300px;">
                                        <div class="style-scroll">
                                            <div v-for="(answer) in ques.listAnswer" :key="answer.id">
                                                <div style="padding: 8px 4px;">+ {{ answer.valueUser ? answer.valueUser
                                                        : answer.value
                                                }}</div>
                                            </div>
                                        </div>
                                    </td>
                                </div>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- loading -->
                <div v-show="loading" class="text-center mt-5">
                    <b-spinner  variant="primary" label="Text Centered"></b-spinner>
                </div>
                <!-- table information-->
                <div style="font-size: 14px;" class="row align-items-center">
                    <div class="col-sm-8 col-xs-6 text-right">
                        <table-info :pagination="pagination" />
                    </div>
                    <div class="col-sm-4 col-xs-6">
                        <table-pagination :pagination="pagination" />
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import PageHeader from '../../../components/page-header';
import TableInfo from '../../../components/datatable/table-info';
import TablePagination from '../../../components/datatable/table-pagination';
import btnChangePage from './partials/btn-change-page.vue';
import {
    httpClient
} from "../../../_utils/httpClient";

export default {
    name: 'list',
    components: {
        TablePagination,
        TableInfo,
        PageHeader,
        btnChangePage
    },
    data: () => ({
        search: '',
        breadcrumbs: [{
            text: 'Trang chủ',
            to: '/admin/home',
        }, {
            text: 'Khảo sát',
            to: '/admin/surveys'
        },
        {
            text: 'Chi tiết đánh giá',
            to: '#',
            active: true,
        },
        ],
        index: 1,
        docId: 0,
        entries: [],
        pagination: {
            limit: 10,
            skip: 0,
            total: 0,
            page: 1,
            search: '',
        },
        loading: true,
    }),
    watch: {
        '$route.query': {
            handler: function () {
                console.log('$route.query', this.$route.query)
                this.getData((this.$route.query.page - 1) * this.pagination.limit, this.$route.query);
            },
        },
    },
    methods: {
        async getData(skip = 0) {
            this.loading = true
            this.docId = this.$route.params.id
            if (isNaN(skip)) {
                skip = 0
            }
            this.pagination.skip = skip
            let response = await httpClient.get(`rate`, {
                params: {
                    ...this.pagination,
                    docId: this.docId
                }
            })
            if (response.status === 200 && response.data.code === 0) {
                this.entries = response.data.data
                this.pagination = {
                    ...this.pagination,
                    total: response.data.total,
                    page: Math.ceil(skip / this.pagination.limit) + 1
                }
            } else {
                this.entries = []
                this.pagination = {
                    ...this.pagination,
                    total: 0,
                    page: 1
                }
            }
            this.loading = false
        },
    },
    created() {
        this.getData();
    },
};
</script>

<style scoped>
.style-scroll {
    max-height: 300px;
    overflow-y: auto;
}

.style-scroll::-webkit-scrollbar {
    width: 6px;
}

.style-scroll::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
.style-scroll::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.style-scroll::-webkit-scrollbar-track {
    background: #f1f1f1;
}
</style>
